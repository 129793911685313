.task-pending .bar {
    fill: #5e72e4 !important;
}

.task-started .bar {
    fill: #11cdef !important;
}

.task-success .bar {
    fill: #2dce89 !important;
}

.task-parked .bar {
    fill: #172b4d !important;
}

.task-finished .bar {
    fill: #f5365c !important;
}

.arrowcp {
    stroke: #f5365c !important;
}

.task-popup {
    display: inline-block;
    padding: 12px;
    width: 300px;
    height: 100%;
}