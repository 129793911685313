.fc-event-card {
    padding: 4px 8px 2px;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(9,30,66,.25);
    cursor: pointer;
}

.fc-toolbar-chunk {
    display: flex;
}

.weekendsCheckbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}