.content-wrap {
    height: 500px;
}

.pg-viewer-wrapper{
    overflow-y: auto !important;
}

.fullscreen-modal {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    margin: 0;
}

.fullscreen-modal-content {
    width: 100vw;
    height: 100vh;
}

.fullscreen-content-wrap {
    height: 85vh;
}

#header-bar {
    display: none;
}

#pdf-download {
    display: none;
}