#attachRiskRatingDropdownsAt .p-datatable-scrollable-body>table>.p-datatable-tbody>tr>td:first-child {
    color: rgba(0,0,0,.54);
    font-weight: 600;
    background: #c5c5c5;
}

#attachRiskRatingDropdownsAt .p-datatable .p-datatable-thead > tr > th {
    color: rgba(0,0,0,.54);
    font-weight: 600;
    background: #c5c5c5;
    font-size: 14px;
}

#attachRiskRatingDropdownsAt .p-datatable-thead tr:last-child {
    display: none;
}

.custom-modal-style {
    width: 80vw;
    max-width: 80vw;
}

#attachRiskRatingDropdownsAt  .p-datatable-scrollable-body {
    min-height: 240px;
}