.file-wrapper {
    padding: 16px 24px;
    background: #fff;
    box-shadow: 1px 1px 6px #afafaf;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.file-icon {
    font-size: 40px;
    color: #3a83c9;
}

.file-info {
    width: 300px;
}
.file-name {
    font-size: 14px;
    font-weight: 600;
}

.file-size {
    font-size: 12px;
    color: #717171;
}

.file-action {
    cursor: pointer;
}

.dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8c8c8c;
    height: 100%;
    padding: 32px;
    border: 1px dashed #a2a2a2;
    margin-bottom: 20px;
    background: #fff;
    height: 200px;
}

.fs-120 {
    font-size: 120px;
}

.file-selector {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: 99;
    cursor: pointer;
}

.dialog-content-wrapper {
    position: relative;
}