.p-datatable-scrollable-body {
    background: #fff;
    min-height: 300px;
}

.table-settings {
    height: 500px;
    width: 100%;
}

.p-filter-column input {
    width: 100%;
}

.no-min-height .p-datatable-scrollable-body {
    min-height: 250px;
}

.card-body .p-datatable.no-min-height .p-datatable-thead > tr > th {
    padding: 10px 10px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.54);
}

.p-datatable .p-datatable-thead .p-sortable-column-icon {
    font-size: 10px;
}

.p-datatable .p-inputtext {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.p-datatable .p-datatable-row {
    font-size: 13px;
}

.p-datatable .p-paginator {
    font-size: 13px;
}

.p-datatable .p-paginator button.p-paginator-element {
    font-size: 13px;
    height: 2rem;
    min-width: 2rem;
}

.p-datatable .p-paginator .p-dropdown {
    height: 2rem;
}

.p-datatable .p-paginator .p-dropdown  .p-dropdown-trigger-icon{
    font-size: 13px;
}

.p-datatable .p-datatable-tbody .p-datatable-emptymessage td {
    text-align: center;
    font-size: 14px;
    color: #757575;
}
.p-datatable .p-inputtext {
    height: 28px;
    margin: 1px 1px;
    border: none;
}

@media only screen and (max-width: 1900px) {
    .datatable-max-height .p-datatable-scrollable-body {
        max-height: 400px !important;
    }
}

.p-datatable {
    font-size: 12px;
}

.p-datatable .p-datatable-thead > tr > th {
    color: rgba(0,0,0,.54);
    font-weight: 600;
}