.each-section {
    height: 100%;
    align-items: center;
    display: flex;
}


@media screen and (max-width: 1400px) {
    .no-margin-b {
        margin-bottom: 0px !important;
    }
    .no-padding-bottom {
        padding-bottom: 0px !important;
    }
}