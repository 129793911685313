.outroute-wrapper {
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 80px;
}

.stickthis {
    position: sticky !important;
    height: 100vh;
}

.back-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-left: 8px;
}

.outroute-title {
    font-size: 34px;
    font-weight: 400;
    line-height: 42px;
    margin-top: 10px;
    margin-bottom: 8px;
}

.outroute-content {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

.outroute-form-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.outroute-left-pannel {
    background: #fff;
    min-height: 100vh;
    z-index: 3;
}

.outroute-right-pannel {
    min-height: 100vh;
}

.outroute-footer {
    position: absolute;
    bottom: 0;
    padding: 16px;
    z-index: 9;
    background-color: #fff;
    width: 100%;
    right: 0;
}

.outroute-right-col-gradient {
    background: linear-gradient(250deg, #B721FF 2.7%, #21D4FD 93.27%);
    position: absolute;
    width: 500px;
    height: 700px;
    filter: blur(90px);
    border-radius: 40%;
    right: 5%;
}

.outroute-right-col {
    background-color: #F3F7FC;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.right-img {
    /* background-image: url("../../assets/img/home/contactus-lady.png"); */
    z-index: 1;
}

.blur-imge {
    background: linear-gradient(220deg, #a897ff 2.7%, #a8d9fe 93.27%);
    position: absolute;
    width: 400px;
    height: 100px;
    filter: blur(40px);
    border-radius: 40%;
    right: 21%;
    bottom: 15%;
    z-index: 2;
}

.right-floating-img {
    position: absolute;
}

.right-img-mushroom {
    top: 22%;
    left: 15%;
    z-index: 3;
    width: 120px;
    height: 120px;
}

.right-img-ring-pink {
    top: 50%;
    left: 20%;
    z-index: 3;
    width: 120px;
    height: 120px;
}

.right-img-sphere-purple {
    top: 50%;
    left: 60%;
    z-index: 3;
    width: 90px;
    height: 90px;
}

.right-img-spring-purple {
    top: 40%;
    left: 70%;
    z-index: 3;
    width: 110px;
    height: 110px;
}

.right-img-ring-blue {
    top: 10%;
    left: 25%;
    z-index: 3;
    width: 100px;
    height: 100px;
}

.right-img-toggle {
    top: 5%;
    left: 45%;
    z-index: 3;
    width: 150px;
    height: 150px;
}

.right-img-cardboard {
    top: 15%;
    left: 70%;
    z-index: 3;
    width: 150px;
    height: 150px;
}

.right-img-calendar {
    top: 35%;
    left: 10%;
    z-index: 3;
    width: 150px;
    height: 150px;
}

.outroute-footer-links {
    font-size: 14px;
    font-weight: 400;
}

@media only screen and (max-width: 600px) {
    .outroute-footer-links {
        display: flex;
        justify-content: space-between;
        margin: 0 40px;
    }
    .outroute-wrapper {
        padding-left: 0;
        padding-right: 0;
        padding-top: 40px;
    }
}

@media only screen and (min-width: 1800px) {
    .blur-imge {
        right: 31%;
        width: 350px;
    }
    
    .outroute-right-col-gradient {
        width: 700px;
        right: 3%;
    }
    
}

@media only screen and (min-width: 2400px) {
    .blur-imge {
        right: 37%;
        width: 350px;
        top: 68%;
    }

    .right-img-mushroom {
        top: 31%;
        left: 31%;
    }

    .right-img-ring-pink {
        top: 49%;
        left: 34%;
    }

    .right-img-sphere-purple {
        top: 50%;
        left: 58%;
    }

    .right-img-spring-purple {
        top: 42%;
        left: 65%;
    }

    .right-img-ring-blue {
        top: 21%;
        left: 37%;
    }

    .right-img-toggle {
        top: 18%;
        left: 48%;
    }

    .right-img-cardboard {
        top: 27%;
        left: 60%;
    }

    .right-img-calendar {
        top: 39%;
        left: 29%;
    }
}