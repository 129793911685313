.profile-pic {
    border-radius: 50% !important;
    width: 180px;
    height: 180px;
}
.upload-icon {
    width: 180px;
    height: 180px;
    position: absolute;
    z-index: 9;
    background: #2f2e2e63;
    transform: translate(-50%, -30%);
    left: 50%;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.15s ease;
}