.upload-picture-modal .up-card-body {
    height: 350px;
}

.upload-picture-modal .dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8c8c8c;
    height: 100%;
    padding: 32px;
    border: 1px dashed #a2a2a2;
    height: 300px;
    background: #fff;
}

.upload-picture-modal .crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 45px;
}

.upload-picture-modal .crop-container .reactEasyCrop_Container {
    margin: 32px;
}

.upload-picture-modal .dialog-content-wrapper {
    position: relative;
}

.upload-picture-modal .controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}