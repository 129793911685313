.main-content .container-fluid.register-container {
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.register-blur-image {
    background: linear-gradient(220deg, #de99ee 2.7%, #a8b6f0 93.27%);
    position: absolute;
    width: 400px;
    height: 130px;
    filter: blur(60px);
    border-radius: 40%;
    left: 20%;
    bottom: 4%;
    z-index: 2;
}

.outroute-register-col-gradient {
    position: absolute;
    width: 600px;
    height: 650px;
    filter: blur(90px);
    border-radius: 40%;
    left: 0%;
    background: linear-gradient(295deg, #FC00FF 0%, #00DBDE 100%);
}

.right-img-cardboard.register-cardboard {
    top: 10%;
    left: 20%;
    width: 134.86px;
    height: 166.59px;
}

.right-img-calendar.register-calendar {
    top: 30%;
    left: 5%;
    width: 192px;
    height: 192px;
}

.right-img-dialog.register-dialog {
    top: 50%;
    left: 5%;
    width: 300px;
    height: 300px;
    z-index: 3;
}

.right-img-toggle.register-toggle {
    top: 20%;
    left: 60%;
    width: 137px;
    height: 137px;
    z-index: 3;
}

.outroute-register-top-content {
    font-size: 34px;
    font-weight: 500;
    line-height: 42px;
    position: absolute;
    top: 2%;
    left: 26%;
    z-index: 3;
    width: 400px;
    text-align: center;
}

.outroute-register-bottom-content {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    position: absolute;
    top: 80%;
    left: 20%;
    z-index: 3;
    width: 500px;
    text-align: center;   
}