.DraftEditor-editorContainer {
    padding: 0 24px;
}

.add-notes-box {
    border: 1px solid rgb(223, 225, 230);
    font-size: 13px;
    padding: 15px;
    width: 100%;
    margin-bottom: 15px;
}

.note-item {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgb(223, 225, 230);
    font-size: 14px;;
}

.note-wrap p {
    font-weight: 400;
}

.note-wrap a {
    text-decoration: underline;
    color: blue;
}
.note-user .note-user-avatar {
    margin: auto;
}

.note-user-name {
    font-weight: bold;
    white-space: nowrap;
    font-size: 14px;
}

.note-created-date {
    font-size: 12px;
}

.note-actions .note-action-button {
    margin-right: 15px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.note-actions .note-action-button:hover {
    color: blue;
}

.comment-section, .note-item {
    width: 40vw;
}

.comment-section {
    display: flex;
}

.comment-box-wrapper {
    border: 1px solid rgb(223, 225, 230);
    margin-bottom: 15px;
}

.note-d-flex {
    display: flex;
}

.note-item {
    width: 40vw;
}

.note-user {
    margin-right: 15px;
    width: 50px;
}

.note-user .media {
    margin-bottom: 10px;
}

.note-user-name {
    width: 350px;
}

.add-notes-box {
    width: 40vw;
    margin-bottom: 30px;
}

.note-edited-tag {
    font-size: 12px;
    font-weight: 800;
}