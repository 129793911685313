@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/DMSans-Medium.ttf')  format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/DMSans-Regular.ttf')  format('truetype');
}

/* 
@font-face {
    font-family: 'DM Sans';
    src: url('../fonts/DMSans-Bold.ttf')  format('truetype');
} */

*, html, body {
    font-family: 'Poppins', 'Open Sans', sans-serif;
}
.main-content {
    transition: all 1s;
}

.fs-11 {
    font-size: 11px;
}

.toggle-card-header {
    cursor: pointer;
    font-size: 14px;
    padding: 15px;
    border: 1px solid #cccccc73;
}

.toggle-card-header:hover, .toggle-card-open .toggle-card-header {
    background: #172b4d;
    color: #fff;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
	display: block;
}

.react-datepicker-manager .react-datepicker-wrapper .react-datepicker__input-container input {
	width: 100%;
}

.cop-card-header {
    font-size: 14px;
}

.required-asterix {
    color: #fb6340;
    margin-left: 5px;
}

.navbar-vertical {
    transition: all 1s;
}

.navbar-vertical.sidenavbar-collapsed {
    max-width: 60px;
}

.expanded-content-area {
    margin-left: 250px;
}

.minimized-content-area {
    margin-left: 60px;
}

.toggle-nav {
    transition: all 1s;
    padding: 5px;
    background: transparent;
    border-radius: 62%;
    width: 24px;
    height: 24px;
    display: flex;
    color: #000;
    align-items: center;
    border: none;
    justify-content: center;
    /* box-shadow: 1px 1px 6px 0px #00000082; */
    cursor: pointer;
    z-index: 999;
    font-size: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
}
.dark-theme .toggle-nav {
    color: #fff;
}

.sidebar-locked {
    background-color: #5bbaef;
    color: #fff;
}
.toggle-nav:hover {
    background-color: #5bbaef;
    color: #fff;
}
.toggle-nav-collapsed {
    left: 50px;
}
.sidebar-bottom-links {
    display: flex;
    justify-content: space-around;
}
.sidebar-bottom-links div {
    font-size: 11px;
    text-align: center;
    cursor: pointer;
    transition: all 1s;
}
.sidebar-bottom-links div:hover {
    text-decoration: underline;
    color: #5bbaef;
}   
.hide-sidebar-component {
    display: none;
}
@media (max-width: 768px) {
    .sidebar-bottom-links {
        display: none;
    }
    .toggle-nav {
        display: none;
    }
}
.p-datatable-tbody>tr>td {
    font-size: 14px;
}
.warning-color {
    color: #e09101;
}

.header.bg-gradient-info {
    background: linear-gradient(to right, #322d8d, #1D49A0, #00cafb, #00cafb) !important;
}
.cop-link {
    color: #5e72e4;
    background-color: transparent;
    cursor: pointer;
}
.cop-link:hover {
    color: #233dd2;
}
.text-small {
    font-size: 12px;
}
:root {
    --avatar-size: 50px;
}

.avatar-circle {
    background-color: #7b7b7b;
    border-radius: 50%;
    height: var(--avatar-size);
    text-align: center;
    width: var(--avatar-size);
    color: #fff;
}

.avatar-initials {
    font-size: calc(var(--avatar-size) / 2);
    line-height: 1;
    position: relative;
    top: calc(var(--avatar-size) / 4);
}

/* TRANSPARENT CARDS */
.card-transparent {
    background: transparent;
    border: none;
}
.card-header-transparent {
    background: transparent;
}
.card-body-pt-0 {
    padding-top: 0;
}
.card-border-radius-0 {
    border-radius: 0;
}

.text-white {
    color: #fff;
}
/* TRANSPARENT TABS DESIGN MODIFICATION */
.nav-tabs .settings-tabs.nav-item .nav-link {
    color: #000 !important;
    background: linear-gradient(87deg, #cecece, #e8e8e8);
    border-left: none;
    padding: 10px 25px;
}

.nav-tabs .settings-tabs.nav-item .nav-link:hover, .nav-tabs .settings-tabs.nav-item .nav-link.active {
    border: 2px solid black;
    border-bottom: none;
    background: #fff;
}

.dark-theme .dt-text-white {
    color: #fff;
}

/* BUTTONS DESIGN MODIFICATION*/
.dark-theme .black-back, .dark-theme button.btn.black-btn,.dark-theme .black-btn-dropdown button.btn {
    background-color: black;
    color: #fff;
}

/* PRIMARY BUTTONS */
.dark-theme button.btn-primary:not(.btn-icon-only):not(.btn-default) {
    background-color: #000;
    color: #fff;
    border: 2px solid #000;
}

.dark-theme button.btn-primary:hover:not(.btn-icon-only) {
    background-color: #333;
    color: #fff;
    border: 2px solid #000;
}

.dark-theme button.btn-primary:disabled:not(.btn-icon-only) {
    background-color: #8c8c8c !important;
    color: #fff;
    border: 2px solid #8c8c8c !important;
}

.dark-theme button.btn-primary.active {
    background-color: #333;
    color: #fff;
    border: 2px solid #000;
}

/* SECONDARY BUTTONS */
.dark-theme button.btn-secondary:not(.btn-icon-only):not(.btn-default) {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
}

.dark-theme button.btn-secondary:hover:not(.btn-icon-only) {
    background-color: #333;
    color: #fff;
    border: 2px solid #000;
}

.dark-theme .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #212529;
    background-color: #d2e3ee;
    border-color: #f7fafc;
}

.primary-color {
    color: #5e72e4;
}

.dark-theme .primary-color {
    color: #000;
}

.hover-primary:hover {
    color: #5e72e4;
}

.dark-theme .hover-primary:hover {
    color: #000;
}

.default-color {
    color: #fff;
}

.dark-theme .default-color {
    color: #000;
}

.page-header {
    position: relative;
}


@keyframes move-up-down {
    0% {
        transform: translateY(-10px); }
    50% {
        transform: translateY(10px); }
    100% {
        transform: translateY(-10px); } 
}
.scroll-down {
    position: absolute;
    bottom: 45px;
    left: 50%;
    transition: 0.5s ease-out;
    animation: move-up-down 1s infinite;
}

.main-content nav.bring-front {
    z-index: 3;
}

.indexpage-link{
    border:none;
    position: relative;
    transition: all ease-in-out .2s;
}
/* 
.indexpage-link::after{
    content: '';
    position: absolute;
    width: 0px;
    height: 3px;
    left: 50%;
    bottom:0;
    background-color: white;
    transition: all ease-in-out .2s;
} */

.indexpage-link:hover{
    border: none;
    cursor: pointer;
}
/* 
.indexpage-link:hover::after{
    width: 100%;
    left: 0;
} */
.indexpage-link.active::after{
    width: 100%;
    left: 0;
}

.form-control {
    color: #8898aa !important;
}
.form-control:focus {
    color: #8898aa !important;
}
.card label {
    color: #8898aa !important;
    font-size: 16px !important; 
}

/* Landing Page design*/

.index-page nav .nav-item .indexpage-link {
    color: #fff;
}

.index-page nav .nav-item .nav-link {
    color: #fff;
}

.text-white-0dot8 {
    color: hsla(0,0%,100%,.8);
}

.index-page .card.card-plain {
    background: transparent;
    box-shadow: none; }

.index-page .card .card-header {
    background-color: transparent;
}

.index-page .card.card-coin .list-group .list-group-item {
    background-color: transparent;
    border: none;
    padding: .4rem 1rem;
}

.index-page .card.card-coin .card-footer {
    padding-bottom: 15px;
}

.index-page .card-plain .card-footer, .card-plain .card-header {
    margin-left: 0;
    margin-right: 0;
    background-color: transparent;
}

.index-page .card.card-coin .list-group {
    margin: 0 auto;
    text-align: center;
}

.navbar-image-auth {
    height: 50px !important;
}

.box-shadow-down {
    box-shadow: 0 15px 20px 0px rgba(0, 0, 0, 0.1) !important;
    border-top: none;
}

.card-desc {
    white-space: nowrap;
}

.fs-20 {
    font-size: 20px;
}

.fs-30 {
    font-size: 30px;
}

i[disabled] {
    color: rgb(0 0 0 / 29%);
}

.cursor-pointer {
    cursor: pointer;
}
.small-profile-pic {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.section {
    padding: 0;
}

.bootstrap-switch {
    background: #5e71e4;
}

.tooltip {
    pointer-events: none;
}

.rdw-link-modal, .rdw-embedded-modal {
    height: unset !important;
    width: 250px !important;
}

.selected-columns .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
    top: 9px;
    left: 2px;
}

.selected-columns.p-multiselect .p-multiselect-label {
    padding: 0.4rem 0.4rem;
}

.selected-columns .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding: 0.4rem 0.4rem;
}

.table-note-display p {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-note-display {
    overflow: hidden;
    height: 30px;
}

.badge-light-warning {
    background-color: rgb(255 193 7 / 50%);
    color: #ad8200;
}

.ws-pre {
    white-space: pre-wrap;
}

.datatable-cell-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-toast-notifications__container {
    z-index: 9999999 !important;
}

.p-datatable.project-task-table .p-datatable-frozen-view {
    border-right: 1px solid #cecece;
}

.p-datatable.project-task-table .p-datatable-thead > tr > th.p-filter-column {
    height: 72px;
}

.p-datatable.project-task-table .p-datatable-tbody > tr > td {
    height: 62px;
}

.calendar_click_details .tooltip-inner {
    background-color: #f7fafc;
    color: #000;
    text-align: left;
    padding: 15px;
    font-size: 12px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.full-width-tooltip .tooltip-inner {
    max-width: 100%;
}

.text-left .tooltip-inner {
    text-align: left;
}

.cop-tooltip .tooltip-inner {
    background-color: #f7fafc;
    color: #000;
    text-align: left;
    padding: 15px;
    font-size: 12px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.success-register-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.success-register-card {
    width: 80%;
}

.overlay {
    z-index: 999;
    background: #1e1e1ea8;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.sub-v-stretch {
    min-height: 525px;
}

.border-very-light {
    border-color: #c1cbd563 !important;
}