.section {
    padding: 70px 0;
    position: relative;

    .row + .category {
        margin-top: $margin-base-vertical;
    }

    .carousel{
      width: 100%;
    }
}

.path {
  position: absolute;
  opacity: 0.02;
}

.dots {
  position: absolute;
  opacity: 0.07;
  z-index: 2;
}

.shapes {
  position: absolute;
  opacity: 0.02;
  z-index: 2;
}

.main {
  position: relative;
  width: $full-width;
}

.section-navbars{
    padding-bottom: 0;
    overflow: hidden;
    .navbar{
      z-index: 2;
    }

    img.path{
      left: -400px;
    }
}

.section-typo{
  overflow: hidden;
  .path{
    right: -400px;
  }

  .path1{
    bottom: 5%;
    width: 70%;
    left: -200px;
  }
}

.section-pagination{
  .path{
    width: 65%;
    right: 0%;
    top: 48%

  }

  .path1{
    width: 30%;
    top: 0;
    left: 3%;
  }
}

.section-javascript {
  overflow: hidden;
  .path{
    right: -300px;
    width: 80%;
  }

  .path1{
    width: 25%;
    left: 3%;
    top: 0;
  }
}

.section-full-screen {
    height: 100vh;
}
.section-signup {
    padding-top: 20vh;
    overflow: hidden;

    .square {
      background-size: cover;
    }
    .square-1 {
      height: 300px;
      width: 300px;
      background-image: url($asset-base-path + "/img/square1.png");
      top: 80px;
      left: 100px;
      opacity: 0.3;
      animation-duration: 5s;
    }

    .square-2{
      height: 200px;
      width: 200px;
      background: url($asset-base-path + "/img/square3.png");
      background-size: cover;
      bottom: 40px;
      left: 600px;
      opacity: 0.1;
      animation-duration: 8s;
    }

    .square-3{
      height: 100px;
      width: 100px;
      background: url($asset-base-path + "/img/square2.png");
      background-size: cover;
      top: 300px;
      left: 700px;
      animation-duration: 5s;
    }

    .square-4{
      height: 250px;
      width: 250px;
      background: url($asset-base-path + "/img/square3.png");
      background-size: cover;
      top: 0px;
      right: 100px;
      opacity: 0.8;
      animation-duration: 9s;
    }
}



.parallax-s{
    overflow: hidden;
    height: 500px;
    width: 100%;
}

.section-image{
    background-size: cover;
    background-position: center center;
    position: relative;
    width: 100%;

    .title,
    .card-plain .card-title{
        color: $white;
    }

    .nav-pills .nav-link{
        background-color: $white;

        &:hover,
        &:focus{
            background-color: $white;
        }
    }

    .info-title,
    .info.info-horizontal .icon i,
    .card-pricing.card-plain ul li{
        color: $white;
    }
    .description,
    .info .icon:not(.icon-circle){
        color: $opacity-8;
    }
    .card:not(.card-plain){
        .info-title{
            color: $black;
        }
        .info p,
        .info .icon,
        .description{
            color: $dark-gray;
        }
    }

    .footer{
        color: $white;
    }
    .card-plain{
        [class*="text-"],
        ul li b{
            color: $white;
        }
        .category{
            color: rgba($white,.5);
        }
    }

    &:after{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0,0,0,.7);
    }

    .container{
        z-index: 2;
        position: relative;
    }
}

.page-header{
    min-height: 100vh;
    max-height: 999px;
    padding: 0;
    color: $white;
    position: relative;
    overflow: hidden;

    .page-header-image{
        position: absolute;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    & > .content{
        margin-top: 11%;
        text-align: center;
        margin-bottom: 50px;
    }

    .signup-page & {
        max-height: fit-content;
    }

    .content-center {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        color: $white;
        padding: 0 15px;
        width: 100%;
        max-width: 880px;

        &.brand {
          width: 47%;
        }

    }

    footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .container{
        height: 100%;
        z-index: 1;
    }

    .category,
    .description{
        color: $opacity-8;
    }

    &.page-header-small{
        min-height: 60vh;
        max-height: 440px;
    }

    &.page-header-mini{
        min-height: 40vh;
        max-height: 340px;
    }

    .title{
        margin-bottom: 15px;
    }
    .title + h4{
        margin-top: 10px;
    }

    &.header-filter:after {
      background: rgba(0,0,0,.5);
    }
}

.clear-filter{
    &:after,
    &:before{
        display: none;
    }
}

.section-basic {
  overflow: hidden;
  .category {
    margin-bottom: 10px;
  }

  img.path{
    right: -400px;
  }
}

.section-story-overview{
    padding: 50px 0;

    .image-container{
        height: 335px;
        position: relative;
        background-position: center center;
        background-size: cover;
        box-shadow: $box-shadow-raised;
        border-radius: .25rem;

        & + .category{
            padding-top: 15px;
        }

        &.image-right{
            z-index: 2;

            + h3.title{
                margin-top: 120px;
            }
        }

        &.image-left{
            z-index: 1;
        }

        img{
            width: 100%;
            left: 0;
            top: 0;
            height: auto;
            position: absolute;
        }

        &:nth-child(2){
            margin-top: 420px;
            margin-left: -105px;
        }
    }

    p.blockquote{
        width: 220px;
        min-height: 180px;
        text-align: left;
        position: absolute;
        top: 376px;
        right: 155px;
        z-index: 0;
    }
}

.section-coins {
  margin-top: 200px;

  h1 {
    margin-bottom: 150px;
  }
}

.section-nucleo-icons {

    --icon-size: 5rem;
    --icon-sm-size: 3.75rem;
    --gutter: 7rem;
    overflow: hidden;

    .title,
    .description,
    .btn-wrapper {
      text-align: center;
    }

    .btn-wrapper {
      margin: 3rem auto;
    }

    .icons-container {
      position: relative;
      z-index: 1;
      max-width: 100%;
      height: 360px;
      margin: 0 auto;
        i {
            position: absolute;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: $white;
            z-index: 1;
            transform: translate(-50%, -50%);
            @include box-shadow($box-shadow);
            transition: all .2s cubic-bezier(.25,.65,.9,.75);

            &.icon {
                width: var(--icon-size);
                height: var(--icon-size);
                font-size: 2em;
            }

            &.icon-sm {
                width: var(--icon-sm-size);
                height: var(--icon-sm-size);
                font-size: 1.7em;
            }

            &:nth-child(1) {
                font-size: 42px;
                z-index: 2;
            }
        }

        &:not(.on-screen) {
            i {
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;

                &:not(:nth-child(1)) {
                    opacity: 0;
                }
            }
        }

        &.on-screen {

            i {
                opacity: 1;

                &:nth-child(1) {
                    left: 50%;
                    top: 50%;
                    font-size: 42px;
                }

                &:nth-child(2) {
                    left: calc(50% + (var(--gutter) * 1.7));
                    top: 50%;
                }

                &:nth-child(3) {
                    left: calc(50% + var(--gutter));
                    top: calc(50% + var(--gutter));
                }

                &:nth-child(4) {
                    left: calc(50% + var(--gutter));
                    top: calc(50% - var(--gutter));
                }

                &:nth-child(5) {
                    left: calc(50% + (var(--gutter) * 4));
                    top: 50%;
                }

                &:nth-child(6) {
                    left: calc(50% + (var(--gutter) * 2.7));
                    top: calc(50% + (var(--gutter) * 1.5));
                }

                &:nth-child(7) {
                    left: calc(50% + (var(--gutter) * 2.7));
                    top: calc(50% - (var(--gutter) * 1.5));
                }

                &:nth-child(8) {
                    left: calc(50% - (var(--gutter) * 1.7));
                    top: 50%;
                }

                &:nth-child(9) {
                    left: calc(50% - var(--gutter));
                    top: calc(50% + var(--gutter));
                }

                &:nth-child(10) {
                    left: calc(50% - var(--gutter));
                    top: calc(50% - var(--gutter));
                }

                &:nth-child(11) {
                    left: calc(50% - (var(--gutter) * 4));
                    top: 50%;
                }

                &:nth-child(12) {
                    left: calc(50% - (var(--gutter) * 2.7));
                    top: calc(50% + (var(--gutter) * 1.5));
                }

                &:nth-child(13) {
                    left: calc(50% - (var(--gutter) * 2.7));
                    top: calc(50% - (var(--gutter) * 1.5));
                }
            }

        }
    }

    .path{
      left: -300px;
      top: 0;
      width: 80%;
    }
}

.section-examples{
  .path{
    width: 55%;
  }

  .container .col-sm-6 img{
    border: 1px solid $primary;
  }
}

.section-download{
  overflow: hidden;
  .path{
    right: -400px;

  }
}


// Blurable sections

.blur-hover {
    position: relative;
    padding-bottom: 100px;

    .blur-item {
        transition: 1s cubic-bezier(.19,1,.22,1);
        will-change: transform;
        filter: blur(0);
        opacity: 1;
    }

    .blur-hidden {
        position: absolute;
        top: calc(50% + 7px);
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: $transition-base;
        z-index: 100;
    }
    &:hover {
        .blur-item {
            opacity: .8;
            filter: blur(10px);
            transform: scale(.95);
            z-index: 1;
        }
        .blur-hidden {
            opacity: 1;
            top: 50%;
        }
    }
}


.section-images{
    max-height: 670px;
    height: 670px;

    .hero-images-container,
    .hero-images-container-1,
    .hero-images-container-2{
        margin-top: -38vh;
    }

    .hero-images-container{
        max-width: 670px;
    }

    .hero-images-container-1{
        max-width: 390px;
        position: absolute;
        top: 55%;
        right: 18%;

    }

    .hero-images-container-2{
        max-width: 225px;
        position: absolute;
        top: 68%;
        right: 12%;
    }
}


[data-background-color]:not([data-background-color="gray"]){
    color: $white;

    .separator-line{
        background-color: $opacity-5;
    }

    .footer{
        &.footer-white .footer-brand,
        &.footer-white ul li > a.nav-link:not(.btn-icon){
            color: initial;
        }
    }

    .card:not(.card-plain) .category,
    .card:not(.card-plain) .card-description,
    & .card:not(.card-plain) .category,
    & .card:not(.card-plain) .category{
        color: $dark-gray;
    }

    .card:not(.card-plain) .card-title,
    & .card:not(.card-plain) .card-title{
        color: initial;
    }

    .carousel .carousel-inner{
        box-shadow: none;
    }

    .title,
    .social-description h2,
    p,
    p.blockquote,
    p.blockquote small,
    h1,h2,h3,h4,h5,h6,
    a:not(.btn):not(.dropdown-item):not(.card-link),
    .icons-container i,
    &.card-pricing ul li,
    .info.info-horizontal .icon i,
    .card-pricing.card-plain ul li{
        color: $white;
    }

    &.card a:not(.btn):not(.dropdown-item){
        color: $white;

        &:hover,
        &:focus{
            border-color: $white;
        }
    }

    &.footer hr,
    &.card-pricing .icon i,
    &.card-pricing ul li{
        border-color: $opacity-2;
    }

    .card-footer .stats i,
    &.card-plain .category,
    .card-plain .category,
    .card-header:after{
        color: $opacity-5;
    }

    &.card-pricing ul li i,
    &.card-pricing ul li b,
    .card-pricing.card-plain ul li b,
    .card-category,
    .author span,
    .card-pricing.card-plain ul li i{
        color: $opacity-8 !important;
    }

    .separator{
        background-color: $white;
    }

    .navbar.bg-white p{
        color: $default;
    }

    .description,
    .social-description p{
        color: $opacity-8;
    }

    p.blockquote{
        border-color: $opacity-2;
    }

    //radio and checkboxes
    .checkbox label::before,
    .checkbox label::after,
    .radio label::before,
    .radio label::after{
        border-color: $opacity-2;
    }

    .checkbox label::after,
    .checkbox label,
    .radio label{
        color: $white;
    }

    .checkbox input[type="checkbox"]:disabled + label,
    .radio input[type="radio"]:disabled + label  {
        color: $white;
    }

    .radio input[type="radio"]:not(:disabled):hover + label::after,
    .radio input[type="radio"]:checked + label::after {
        background-color: $white;
        border-color: $white;
    }

    //inputs
    

    .input-group-text,
    .form-group.form-group-no-border .input-group-text,
    .input-group.form-group-no-border .input-group-text{
        color: $opacity-8;
    }

    .subscribe-line{
        .form-control{
            background-color: $transparent-bg;
            border: 1px solid $light-gray;
            color: $black;

            &:last-child{
                border-left: 0 none;
            }
        }

        .input-group-text,
        .form-group.form-group-no-border .input-group-text,
        .input-group.form-group-no-border .input-group-text{
            color: $dark-background;
            border: 1px solid $light-gray;
        }
    }


    //buttons
    .btn.btn-simple{
        background-color: $transparent-bg;
        border-color: $opacity-5;
        color: $white;

        &:hover,
        &:hover,
        &:focus,
        &:active{
            background-color: $transparent-bg;
            border-color: $white;
        }
    }

    //tabs
    .nav-tabs{
        > .nav-item{
            >.nav-link{
                i.now-ui-icons{
                    color: $white;
                }
            }
        }
    }

    &.section-nucleo-icons .icons-container i:nth-child(6){
        color: $white;
    }
}

[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"]{
    padding: 80px 0;
}


@media screen and (min-width: 991px){
  .section-safe {
    .card.card-stats {
      position: absolute;
      &:nth-of-type(1) {
        width: 140px;
        top: -55px;
        left: 200px;
      }
      &:nth-of-type(2) {
        width: 200px;
        top: 150px;
        left: -81px;
      }
      &:nth-of-type(3) {
        width: 130px;
        top: 240px;
        left: 300px;
      }

      .numbers {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 991px){
  .section-signup{
    .card-register{
      margin-top: 50px;
    }
  }

  .landing-page .section:nth-of-type(3) {
      padding-top: 100px;
  }

  .section-safe img.img-fluid{
    display: none;
  }

  .section-coins {
    margin-top: 100px;
  }

  #carouselExampleIndicators{
    margin-top: 210px;
  }

}
@media screen and (max-width: 768px){
  .section-safe {
    .card.card-stats {
      margin-top: 10px;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: auto;
      }
    }
  }
}

@media screen and (max-width: 768px){

  .section-examples .container .col-sm-6:nth-child(2) img {
      border: 1px solid #e14eca;

  }
}



// Color swatches
.color-swatch {
    margin: 1rem 0;
    border-radius: .25rem;
    background-color: #F4F5F7;
    width: 100% !important;
    height: auto !important;
    margin-bottom: 20px;
}

.color-swatch:after {
    content: " ";
    display: table;
    clear: both;
}

.color-swatch-header {
    position: relative;
    height: 0;
    padding-bottom: 50%;
    border-radius: .25rem .25rem 0 0;
    border: 1px solid transparent;
}

.color-swatch-header.is-light {
    border-color: #C1C7D0;
}

.color-swatch-header .pass-fail {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.color-swatch-header .pass-fail-item-wrap {
    position: relative;
    float: left;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.color-swatch-header .pass-fail-item-group {
    display: inline-block;
    padding: 0 5px;
}

.color-swatch-header .pass-fail-item {
    float: left;
    display: inline-block;
    text-align: center;
    padding: 2px;
}

.color-swatch-header .pass-fail-item.white .example {
    color: #fff;
}

.color-swatch-header .pass-fail-item.small .example {
    font-size: 10px;
}

.color-swatch-header .pass-fail-item .lozenge {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    background: #000;
    color: #fff;
    padding: 2px 4px;
    line-height: 10px;
    border-radius: 4px;
    letter-spacing: 0.05em;
}

.color-swatch-body {
    position: relative;
    left: 50%;
    float: left;
    padding: 10px 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.color-swatch-body .prop-item-wrap {
    float: left;
    padding: 0 15px;
    min-width: 65px;
}

.color-swatch-body .prop-item {
    padding: 15px 0;
}

.color-swatch-body .prop-item .label {
    font-size: 11px;
    color: #62748C;
    text-transform: uppercase;
    line-height: 16px;
}

.color-swatch-body .prop-item .value {
    font-size: 14px;
}

.table-colors .swatch{
  display: inline-block;
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.table-colors td:nth-child(1),
.table-colors:first-child td:nth-child(1) {
    line-height: 40px;
}

.table-colors .lozenge {
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    display: inline-block;
    float: left;
    margin: 5px 10px 0 0;
    padding: 2px 4px;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #042a53;
    border-radius: 4px;
    background: #97a0af;
}

.bd-example .row>.col span,
.bd-example .row>[class^=col-] span{
    font-size: $font-size-sm;
    display: block;
    margin: 1rem 0;
    padding: .75rem;
    color: #393f49;
    background-color: $white;
    border-radius: $border-radius-sm;
}

//
@import 'sections/_headers';
// @import 'sections/_features';
// @import 'sections/_blogs';
// @import 'sections/_team';
// @import 'sections/_projects';
// @import 'sections/_pricing';
// @import 'sections/_testimonials';
// @import 'sections/_contactus';
//
// @import 'sections/_social-subscribe-lines';


// style for the landing page
.index-page {
    .page-header {
        height: 100vh;

        .container{
            > .content-center {
                &.brand {
                  .h1-seo,
                  h3{
                    color: $white;
                    font-weight: 600;
                    text-transform: capitalize;
                    span{
                      font-weight: $font-weight-bold;
                    }
                }
              }
            }
        }

        .category-absolute {
          position: absolute;
          top: 100vh;
          margin-top: -60px;
          padding: 0 15px;
          width: 100%;
          color: rgba(255,255,255,.5);
        }
    }

    .squares{
      animation: move-left-right 1s infinite;
      position: absolute;
      transition: 0.5s ease-out;
      overflow: hidden;
      border-radius: 20%;
      &.square1{
        animation: move-left-right 4s infinite;
        height: 300px;
        width: 300px;
        opacity: 0.5;
        left: 3%;
        top: -21%;
      }

      &.square2{
        animation: move-left-right 6s infinite;
        height: 400px;
        width: 400px;
        opacity: 0.4;
        right: -5%;
        top: -12%;
      }

      &.square3{
        animation: move-left-right 5s infinite;
        height: 200px;
        width: 200px;
        opacity: 0.1;
        left: -5%;
        bottom: 0%;
      }

      &.square4{
        animation: move-left-right 10s infinite;
        height: 100px;
        width: 100px;
        opacity: 0.9;
        right: 27%;
        top: 70%;
      }

      &.square5{
        animation: move-left-right 6s infinite;
        height: 250px;
        width: 250px;
        opacity: 0.1;
        left: 32%;
        bottom: 29%;
      }

      &.square6{
        animation: move-left-right 9s infinite;
        left: 10%;
        top: 35%;
        height: 80px;
        width: 80px;
        opacity: 0.8;
      }

      &.square7{
        animation: move-left-right 3s infinite;
        width: 300px;
        height: 300px;
        right: -5%;
        bottom: 0%;
        opacity: 0.1;
      }
    }
}
.landing-page {
  overflow-x: hidden;

  .page-header {

    &::after {
      background: radial-gradient(ellipse at top right, #292D61 23%, #171941 65%);
      // content: "";
      position: absolute;
      height: 100vh;
      width: 100%;
      opacity: .5;
    }
    .path {
      max-width: 65%;
      right: 0;
      top: 30px;
    }

    .path2 {
      position: absolute;
      opacity: 0.02;
      max-width: 41%;
      right: -60px;
      top: -50px;
    }

    .squares {
      top: 50%;
      right: 18%;
      max-height: 200px;
    }

    .wave {
      top: 45%;
      right: 30%;
      max-height: 90px;
    }

    .circle {
      top: 20%;
      right: 36%;
      max-height: 75px;
    }

    .triangle {
      top: 35%;
      left: 5%;
      max-height: 200px;
    }
  }

  .section:first-of-type {
    .path {
      left: -110px;
      max-width: 60%;
      top: -50px;
    }
  }
  .section:nth-of-type(2) {

    .path {
      left: auto;
      right: -250px;
      max-width: 70%;
      top: 0;
    }
    .path2 {
      position: absolute;
      opacity: 0.02;
      right: auto;
      left: 420px;
      max-width: 10%;
      top: 420px;
    }

    .path3 {
      position: absolute;
      opacity: 0.02;
      left: auto;
      right: 500px;
      max-width: 10%;
      top: -90px;
    }
  }
  .section:nth-of-type(3) {
    padding-top: 200px;

    .path {
      right: auto;
      left: 50px;
      max-width: 45%;
      top: 60px;
    }
  }
  .section:nth-of-type(4) {
    .path {
      max-width: 60%;
      right: 3%;
      top: 15%;
    }
    .path2 {
      position: absolute;
      opacity: 0.02;
      left: 25%;
      right: auto;
      max-width: 10%;
      top: 100px;
    }
  }
  .section:nth-of-type(5) {
    .path {
      max-width: 50%;
      left: 5%;
      top: 3%;
    }
  }

  .header{
      height: 100vh;
      position: relative;

      .container{
          padding-top: 26vh;
          color: $white;
          z-index: 2;
          position: relative;
      }

      .share{
          margin-top: 150px;
      }
      h1{
          font-weight: 600;
      }
      .title{
          color: $white;
      }
  }

    .section-team{
        .team .team-player img{
            max-width: 100px;
        }

        .team-player{
            margin-bottom: 15px;
        }
    }

    .section-contact-us{
        .title{
            margin-bottom: 15px;
        }

        .description{
            margin-bottom: 30px;
        }

        .input-group,
        .send-button,
        .textarea-container{
            padding: 0 40px;
        }

        .textarea-container{
            margin: 40px 0;
        }

        a.btn{
            margin-top: 35px;
        }
    }

    .section-safe {
      .icon {
        i {
          font-size: 21px;
        }
      }
    }
}

// style for the profile page
.profile-page{

  overflow-x: hidden;

    .page-header{
      min-height: 550px;

      .container {
        padding-top: 30vh;
      }

      .path {
        max-width: 65%;
        right: 0;
        top: 100px;
      }
    }

    .info {
      padding: 40px 0 0;

      .description {
        text-align: left;
        padding-left: 20px;
      }
    }

    .profile-title {
      position: absolute;
      top: 110px;
      left: 60px;
    }

    .profile-description {
      margin-top: -30px;
      max-width: 450px;
      font-size: $font-size-base;
      line-height: 26px;
    }

    .profile-container {
        color: $white;
    }
    .photo-container {
      max-width: 400px;
      overflow: hidden;
      -webkit-box-shadow: -15px 20px 60px 0px rgba(0,0,0,0.30);
      -moz-box-shadow: -15px 20px 60px 0px rgba(0,0,0,0.30);
      box-shadow: -15px 20px 60px 0px rgba(0,0,0,0.30);
    }

    .title{
      text-align: center;
      margin-top: 30px;
    }

    .description,
    .category{
        text-align: center;
    }

    h5.description {
        max-width: 700px;
        margin: 20px auto 75px;
    }

    .nav-align-center {
        margin-top: 30px;
    }

    .content {
      .social-description {
        display: inline-block;
        max-width: 150px;
        width: 145px;
        text-align: center;
        margin: 15px 0 0px;

        h2 {
          margin-bottom: 15px;
        }
      }
    }

    .collections {
        img {
            margin-bottom: 30px;
        }
    }

    .gallery {
        margin-top: 45px;
        padding-bottom: 50px;
    }

    .card-coin {
      .list-group{
        margin: 0 !important;
        text-align: left !important;
        color: $opacity-5;
      }
    }
    .card {
      .card-header {
        .profile-title {
          top: 125px;
        }
      }
    }
}

.section-full-page{

    &:after,
    &:before{
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &:before{
        background-color: rgba(0,0,0,.5);
    }

    &[data-image]:after{
        opacity: .5;
    }

    > .content,
    > .footer{
        position: relative;
        z-index: 4;
    }

    > .content{
        min-height: calc(100vh - 80px);
    }

    .full-page-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;

    }

    .footer nav > ul a:not(.btn),
    .footer,
    .footer .copyright a{
        color: $white;
    }

}

.register-page{

    background-color: $background-black;

    .link{
        font-size: 10px;
        color: $white;
        text-decoration: none;
    }



    .register-bg{
      height: 700px;
      background: linear-gradient(to bottom, $transparent-bg 0%, $card-black-background 100%);
      width: 700px;
      position: relative;
      right: -600px;
      top: -750px;
      border-radius: 20%;
      transform: rotate(-15deg);
    }

    .square{
      position: absolute;
      transition: 0.5s ease-out;
      border-radius: 2px;
      background-size: 100%;
      border-radius: 20%;
    }

    .square-1{
      height: 300px;
      width: 300px;
      background-image: url($asset-base-path + "/img/square1.png");
      top: 175px;
      right: 150px;
      opacity: 0.8;
    }

    .square-2{
      height: 120px;
      width: 120px;
      background-image: url($asset-base-path + "/img/square2.png");
      opacity: 0.9;
      top: 310px;
      right: 390px;
    }

    .square-3{
      width: 200px;
      height: 200px;
      background-image: url($asset-base-path + "/img/square3.png");
      opacity: 0.3;
      top: -50px;
      right: 400px;
    }

    .square-4{
      height: 200px;
      width: 200px;
      background-image: url($asset-base-path + "/img/square4.png");
      right: -50px;
      top: 525px;
    }

    .square-5{
      height: 150px;
      width: 150px;
      background-image: url($asset-base-path + "/img/square5.png");
      opacity: 0.5;
      top: 500px;
      right: 470px;
    }

    .square-6{
      height: 70px;
      width: 70px;
      background-image: url($asset-base-path + "/img/square5.png");
      opacity: 0.9;
      top: 105px;
      right: 20px;
    }

    .square-7{
      height: 200px;
      width: 200px;
      background-image: url($asset-base-path + "/img/square5.png");
      left: -100px;
      top: 100px;
      opacity: .4;
    }

    .square-8{
      height: 100px;
      width: 100px;
      background-image: url($asset-base-path + "/img/square1.png");
      left: 300px;
      top: 400px;
      opacity: .4;
    }
}

.index-page,
.landing-page,
.profile-page,
.register-page{
  background-image: url($asset-base-path + "/img/dots.png");
  background-size: contain;
}

@include media-breakpoint-down(md){
  .section:nth-of-type(2) {
    .path {
      left: 10% !important;
    }
    .path2 {
      left: 20% !important;
    }
  }

  .info:first-of-type {
      padding-top: 10% !important;
  }
}

@include media-breakpoint-down(sm){
  .card-login img{
    width: 70%;
  }

  .section:nth-of-type(2) {
    .path,
    .path2 {
      left: 0 !important;
    }
  }
  .section-coins {
    margin-top: 0 !important;
  }
}

@include media-breakpoint-down(xs){
  .card-login {
    margin-top: 100px;
      img{
      width: 350px;
    }
  }

  .profile-page .page-header{
    max-height: unset;
  }

  .landing-page{
    .page-header{
      h1{
        margin-top: 100px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .info:first-of-type {
    padding-top: 60%;
  }
}

img{
    max-width: 100%;
    border-radius: $border-radius-sm;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}

.img-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}


.info{
    max-width: 360px;
    margin: 0 auto;
    padding: 70px 0 30px;

    .icon{
        color: $default;

        > i{
            font-size: $h1-font-size;
        }
    }
    .info-title{
        color: $white;
        margin: 0.875rem * 2 0 0.875rem;
    }
    p{
        color: $opacity-gray-8;
        line-height: 24px;
    }
}

hr {
  width: 10%;
  height: 1px;
  border-radius: 3px;
  margin-left: 0;

  &.line-primary {
    background-color: $primary;
  }
  &.line-info {
    background-color: $info;
  }
  &.line-success {
    background-color: $success;
  }
  &.line-warning {
    background-color: $warning;
  }
  &.line-danger {
    background-color: $danger;
  }
  &.line-deafult {
    background-color: $default;
  }
}

.info-horizontal{
    .icon{
        float: left;
        margin-top: 24px;
        margin-right: 10px;

        >i{
            font-size: $h1-font-size;
        }
    }
    .description{
        overflow: hidden;
    }

}

.icon {
  &.icon-primary {
    color: $primary;
    }
  &.icon-info {
    color: $info;
  }
  &.icon-success {
    color: $success;
  }
  &.icon-warning {
    color: $warning;
  }
  &.icon-danger {
    color: $danger;
  }

}


// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 { font-size: $h1-font-size; }
h2, .h2 { font-size: $h2-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
}

// Type display classes
.display-1 {
  font-size: $display1-size;
  font-weight: $display1-weight;
  line-height: $display-line-height;
}
.display-2 {
  font-size: $display2-size;
  font-weight: $display2-weight;
  line-height: $display-line-height;
}
.display-3 {
  font-size: $display3-size;
  font-weight: $display3-weight;
  line-height: $display-line-height;
}
.display-4 {
  font-size: $display4-size;
  font-weight: $display4-weight;
  line-height: $display-line-height;
}


//
// Horizontal rules
//

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}


//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $spacer;
  font-size: $blockquote-font-size;
}

.blockquote-footer {
  display: block;
  font-size: 80%; // back to default font-size
  color: $blockquote-small-color;

  &::before {
    content: "\2014 \00A0"; // em dash, nbsp
  }
}
