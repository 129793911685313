nav.navbar-vertical.side-nav {
    z-index: 999;
    padding-left: 0;
    padding-right: 0;
}
.dark-theme .side-nav .nav-link {
    color: #fff !important;
}
nav.navbar-vertical.side-nav .navbar-collapse {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-left: 0;
    margin-right: 0;
}
.side-nav.sidenavbar-collapsed .navbar-brand-img {
    padding: 10px;
}

.dark-theme nav.navbar-vertical.side-nav {
    background-color: #000;
    color: #fff;
}
.light-theme nav.navbar-vertical.side-nav {
    background-color: #f8f9fe;
}
