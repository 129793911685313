.timeline-container .timeline {
    list-style-type: none;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }
  
  .timeline-container .timeline .li {
    transition: all 200ms ease-in;
    display: inline-block;
    padding: 30px 0px;
  }
  
  .timeline-container .timestamp {
    margin-bottom: 20px;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 100;
  }
  
  .timeline-container .status {
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    border-top: 2px solid #D6DCE0;
    position: relative;
    transition: all 200ms ease-in;
  }
  .timeline-container .status h4 {
    font-weight: 600;
  }
  .timeline-container .status:before {
    content: "";
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 25px;
    border: 1px solid #ddd;
    position: absolute;
    top: -15px;
    left: 42%;
    transition: all 200ms ease-in;
  }
  
  .timeline-container .li.complete .status {
    border-top: 2px solid #66DC71;
  }
  .timeline-container .li.complete .status:before {
    background-color: #66DC71;
    border: none;
    transition: all 200ms ease-in;
  }
  .timeline-container .li.complete .status h4 {
    color: #66DC71;
  }
  
  @media (min-device-width: 320px) and (max-device-width: 700px) {
    .timeline-container .timeline {
      list-style-type: none;
      display: block;
    }
  
    .timeline-container .li {
      transition: all 200ms ease-in;
      display: flex;
      width: inherit;
    }
  
    .timeline-container .timestamp {
      width: 100px;
    }
  
    .timeline-container .status:before {
      left: -8%;
      top: 30%;
      transition: all 200ms ease-in;
    }
  }
/*   
.timeline-container button {
    position: absolute;
    width: 100px;
    min-width: 100px;
    padding: 20px;
    margin: 20px;
    font-family: "Titillium Web", sans serif;
    border: none;
    color: white;
    font-size: 16px;
    text-align: center;
} */
  
.timeline-container #toggleButton {
    position: absolute;
    left: 50px;
    top: 20px;
    background-color: #75C7F6;
}

.timeline-container .timestamp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.timeline-container .status {
    white-space: nowrap;
}

.milestone-form {
    border-bottom: 1px solid #cecece;
    margin-bottom: 20px;
    padding-left: 20px;
}

.mb-1dot5rem {
    margin-bottom: 1.5rem;
}

.color-pick-wrapper {
  position: absolute;
  z-index: 99;
}

.color-picker {
  width: 30px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #7d7c7c;
}
.timeline-task-subtitle {

}

.timeline-task-title {
  font-weight: bold;
}