.timelineComponentPopover:after, .timelineComponentPopover:before {
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.timelineComponentPopover:after {
    border-color: rgba(255, 255, 255, 0);
    border-width: 10px;
    margin-left: -8px;
}

.timelineComponentPopover:before {
    border-color: rgba(245, 0, 0, 0);
    border-width: 13px;
    margin-left: -11px;
}

.timelineComponentPopoverDown:after, .timelineComponentPopoverDown:before {
    bottom: 100%;
}

.timelineComponentPopoverDown:after {
    border-bottom-color: #ffffff;
}
.timelineComponentPopoverDown:before {
    border-bottom-color: rgb(151, 151, 151);
}

.timelineComponentPopoverUp:after, .timelineComponentPopoverUp:before {
    top: 100%;
}
.timelineComponentPopoverUp:before {
    border-top-color: rgb(151, 151, 151);
}

.timelineComponentPopoverUp:after {
    border-top-color: #ffffff;
}
 
.timelineComponentPopoverUp {
    bottom: 50px;
}



.timelineCircleLinkUp:after{
    top: 100%;
}

.timelineCircleLinkDown:after {
    bottom: 100%;
}

.timelineCircleLinkUp {
    bottom: 60px;
}

.timelineCircleLinkDown:after, .timelineCircleLinkUp:after {
    border: none;
    height: 22px;
    box-shadow: 0px 0px 3px 0px #000;
    position: absolute;
    content: " ";
}


@media print {
    .timelineComponentPopover:after, .timelineComponentPopover:before {
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    
    .timelineComponentPopover:after {
        border-color: rgba(255, 255, 255, 0);
        border-width: 10px;
        margin-left: -8px;
    }
    
    .timelineComponentPopover:before {
        border-color: rgba(245, 0, 0, 0);
        border-width: 13px;
        margin-left: -11px;
    }
    
    .timelineComponentPopoverDown:after, .timelineComponentPopoverDown:before {
        bottom: 100%;
    }
    
    .timelineComponentPopoverDown:after {
        border-bottom-color: #ffffff;
    }
    .timelineComponentPopoverDown:before {
        border-bottom-color: rgb(151, 151, 151);
    }
    
    .timelineComponentPopoverUp:after, .timelineComponentPopoverUp:before {
        top: 100%;
    }
    .timelineComponentPopoverUp:before {
        border-top-color: rgb(151, 151, 151);
    }
    
    .timelineComponentPopoverUp:after {
        border-top-color: #ffffff;
    }
     
    .timelineComponentPopoverUp {
        bottom: 50px;
    }
    
    
    
    .timelineCircleLinkUp:after{
        top: 100%;
    }
    
    .timelineCircleLinkDown:after {
        bottom: 100%;
    }
    
    .timelineCircleLinkUp {
        bottom: 60px;
    }
    
    .timelineCircleLinkDown:after, .timelineCircleLinkUp:after {
        border: none;
        height: 22px;
        box-shadow: 0px 0px 3px 0px #000;
        position: absolute;
        content: " ";
    }
}



.high-placement:after{
    height: 115px !important;
}
/* Testing out new timeline*/

.high-placement {
    bottom: 150px;
}