.home-cont {
    padding: 0;
}

.main-content .container-fluid {
    /* padding-left: 0 !important;
    padding-right: 0 !important; */
}

.section {
    min-height: 800px;
}

.section.top-section .top-section-content-l {
    margin-top: 28%;
    /* padding-left: 4%; */
    /* width: 100%; */
}

.section.task-section {
    margin-top: 30%;
}

.top-section-sub-content, .slider-subcontent {
    font-size: 18px;
    line-height: 30px;
}

.section-sub-content {
    font-size: 16px;
    line-height: 28px;
}

.mt-64 {
    margin-top: 64px;
}

body.index-light-theme {
    background-color: #F3F7FC;
    color: #000000;
}

body.index-light-theme *:not(.fa-font) {
    font-family: 'DM Sans';
}

.section-title {
    font-size: 64px;
    font-weight: 500;
    line-height: 72px;
}

.feature-title {
    font-size: 34px;
    font-weight: 500;
    line-height: 42px;
}

.price-change {
    display: flex;
    justify-content: center;
    align-items: center;
}

.price-card-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 8px;
    white-space: nowrap;
}

.section-price-card-title {
    font-size: 42px;
    text-align: center;
    font-weight: 500;
    line-height: 56px;
    margin-bottom: 32px;
}

.price-subtitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 8px;
}

.top-section-right {
    padding-left: 50px;
}

.image-border-left {
    padding: 25px 0 25px 25px;
    background: linear-gradient(135deg, #9DA7FF 0%, #003BFF 100%);
    border-radius: 23px 0 0 23px;
    position: absolute;
    right: -24px;
}

.image-border-right {
    padding: 30px 30px 30px 0;
    background: linear-gradient(316deg, #92FE9D 10.84%, #00C9FF 95.27%);
    border-radius: 0 23px 23px 0;
    position: absolute;
    left: -24px;
}

.section-1 {
    margin-top: 20%;
}

.task-list-r {
    margin-top: 55%;
}

.calendar-section {
    margin-top: 15%;
}

.calendar-section-l {
    margin-top: 22%;
}

.calendar-section-r {
    margin-top: 13%;
}

.execution-section {
    margin-top: 15%;
}

.execution-gradient {
    /* background: linear-gradient(180deg, rgba(0, 194, 255, 0) 0%, #568aff 100%); */
    background: linear-gradient(180deg, rgba(0, 194, 255, 0) 0%, #128af7 100%);
    position: absolute;
    top: -820px;
    left: -240px;
    width: 1200px;
    height: 1400px;
    filter: blur(100px);
    z-index: -1;
    border-radius: 50%;
}

.calendar-gradient {
    background: linear-gradient(180deg, #6689FF 0%, rgba(220, 39, 95, 0.510417) 48.96%, rgba(245, 138, 37, 0) 100%, #3D67F0 100%);
    position: absolute;
    right: 25%;
    top: 30%;
    width: 498px;
    height: 746px;
    filter: blur(50px);
    border-radius: 30%;
    z-index: -1;
}

.execution-section-r {
    margin-top: 32%;
}

.image-1 {
    margin-top: 38%;
}

.image-2 {
    margin-top: 200px;    
}

.toggle-icon {
    position: absolute;
    top: -150px;
    left: -125px;
    width: 222px;
}

.cardboard-icon {
    position: absolute;
    top: -185px;
    left: 87px;
    width: 163px;
}

.calendar-icon {
    position: absolute;
    top: -130px;
    left: 270px;
    width: 210px;
}

.dialog-icon {
    position: absolute;
    top: -175px;
    right: 0px;
    width: 220px;
}

.section-1-blur-gradient {
    position: absolute;
    top: -100px;
    width: 650px;
    height: 750px;
    background: linear-gradient(295deg, #FC00FF 0%, #00DBDE 100%);
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: -1;
    right: 0;
    border-radius: 50% 50% 35% 50%;
}

.section-1-blur-gradient1 {
    position: absolute;
    top: 212px;
    width: 500px;
    height: 255px;
    background: linear-gradient(295deg, #FC00FF 0%, #00DBDE 100%);
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: -1;
    right: 0;
}

.task-blue-gradient {
    position: absolute;
    top: -120px;
    width: 820px;
    height: 820px;
    background: linear-gradient(180deg, #42DDFF 0%, rgba(17, 112, 255, 0.46) 100%);
    filter: blur(50px);
    z-index: -1;
    left: -200px;
    border-radius: 100%;
}

.task-pink-gradient {
    position: absolute;
    top: -10%;
    width: 404px;
    height: 404px;
    background: linear-gradient(180deg, #F22FB0 0%, rgba(245, 138, 37, 0) 100%, #7061A3 100%);
    filter: blur(50px);
    z-index: -1;
    left: 50%;
    border-radius: 50%;
}

.px-10-per {
    padding: 0 10%;
}

.px-7-per {
    padding: 0 7%;
}

.price-title, .slider-title {
    font-size: 42px;
    line-height: 56px;    
    text-align: center;
    margin-bottom: 30px;
    margin-top: 5%;
}

.price-subcontent {
    font-size: 18px;
    line-height: 30px;    
    text-align: center;
    width: 550px;
    margin: auto;
}

.mt-200 {
    margin-top: 200px;
}

.mb-200 {
    margin-bottom: 200px;
}

.index-list li {
    line-height: 32px;
    font-size: 22px;
    margin-bottom: 24px;
}

.icon-shadow {
    filter: drop-shadow(0px 24px 44px #202F444D);
}

.index-button-primary {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    background-color: #0D45FF;
    border: 2px solid #0D45FF;
    border-radius: 36px;
    padding: 13px 40px;
    box-shadow: none;
    cursor: pointer;
}

.index-button-primary:hover {
    background-color: #98B0FF;
    border: 2px solid #98B0FF;
    color: #fff;
    box-shadow: none;
}

.index-button-secondary {
    font-size: 16px;
    font-weight: 500;    
    line-height: 24px;
    color: #000;
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 36px;
    padding: 13px 40px;
    box-shadow: none;
    cursor: pointer;
}

.index-button-secondary:hover {
    background-color: #617693;
    border: 2px solid #617693;
    color: #fff;
    box-shadow: none;
}

.index-button-black {
    font-size: 16px;
    font-weight: 500;    
    line-height: 24px;
    color: #fff;
    background-color: #000;
    border: 2px solid #000;
    border-radius: 36px;
    padding: 13px 40px;
    cursor: pointer;
}

.index-button-black:hover {
    background-color: #D4DEEB;
    border: 2px solid #D4DEEB;
    color: #000928;
    box-shadow: none;
}

.icon-width {
    width: 30px;
}

.w-20 {
    width: 20px;
}

.border-blue-gradient {
    background: linear-gradient(60.28deg, #4481EB 4.81%, #04BEFE 97.47%);
}

.calendar-img-wrapper {
    padding: 30px 0 30px 30px;
}

.execution-image-border {
    padding: 30px 30px 30px 30px;
    background: linear-gradient(295deg, #FC00FF 0%, #00DBDE 100%);
    border-radius: 23px;
}

.execution-image-l {
    padding: 12%;
}

.feature-subcontent {
    font-size: 14px;
    line-height: 24px;
}

.discount-tag {
    font-size: 12px;
    padding: 4px 6px;
    background-color: rgba(255, 88, 80, 1);
    text-align: center;
    border-radius: 6px;
    color: #fff;
}

.price-card {
    border-radius: 42px;
    padding: 38px 55px;
    margin: 17px;
    text-align: center;
}

.price-free, .price-standard {
    background: radial-gradient(100% 100% at 0% 0%, rgba(130, 174, 240, 0.408) 0%, rgba(104, 153, 226, 0.15) 100%);
}

.price-premium {
    background: radial-gradient(103.63% 103.73% at 103.92% 102.8%, #0D45FF 0%, #100095 100%);
    color: #fff;
}

.price-feature-list {
    text-align: left;
    min-height: 150px;
}

.price-feature {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 8px;
    white-space: nowrap;
}

.price-divider {
    margin-top: 25px;
    border-bottom: 1px solid;
    margin-bottom: 32px;
}

.price-card-price {
    font-size: 64px;
    font-weight: 500;
    line-height: 72px;
}

.price-per {
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 32px;
}

.btn-price-premium {
    padding: 13px 40px;
    color: #0D45FF;
    background-color: #fff;
    border: 2px solid #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 36px;
}

.section-price-card {
    padding: 60px;
}

.price-feature-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 16px;
}

.section-price-feature {
    padding: 40px 100px;
}

.col-price-feature {
    padding: 0 40px;
}

.section-price-feature-2 {
    margin-bottom: 300px;
}

.section-testimonial {
    background: linear-gradient(180deg, #E9EDF7 0%, rgba(233, 237, 247, 0) 100%);
    min-height: 600px;
    margin-left: -39px;
    margin-right: -39px;
    margin-bottom: 200px;
    margin-top: 200px;
    justify-content: center;
}

.price-feature {
    padding-left: 10px;
}

.testimonial-card {
    height: 500px;
    text-align: center;
    position: relative;
    background: #fff;
    top: -110px;
    border-radius: 32px;
    border: 1px solid #F3F7FC;
    box-shadow: 0px 10px 60px 0px #00000014;
    padding: 62px 48px;
}

.testimonial-image {
    height: 106px;
    width: 106px;
    border-radius: 50%;
    display: flex;
    background: linear-gradient(135deg, #9DA7FF 0%, #003BFF 100%);
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
    margin-bottom: 20px;
}

.testimonial-image img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    z-index: 2;
}

.testimonial-img-gradient {
    position: absolute;
    right: 20px;
    background: linear-gradient(295deg, #FC00FF 0%, #00DBDE 100%);
    width: 50px;
    top: 45px;
    height: 50px;
    filter: blur(30px);
}

.testimonial-gradient {
    position: absolute;
    left: -10px;
    top: 26%;
    background: linear-gradient(180deg, #6689FF 0%, rgba(220, 39, 95, 0.510417) 48.96%, rgba(245, 138, 37, 0) 100%, #3D67F0 100%);
    width: 740px;
    height: 462px;
    filter: blur(30px);
    z-index: -1;
    border-radius: 40%;
}

.testimonial-user-name {
    font-size: 18px;
    font-weight: 700;
}

.testimonial-content-wrap {
    max-width: 450px;
    margin: auto;
}

.section-footer {
    padding: 125px 190px 65px 190px;
    background: #FAFCFE;
    margin-left: -39px;
    margin-right: -39px;
}

.social-icon {
    margin-left: 40px;
}

.social-icons {
    text-align: right;
}

.footer-links {
    text-align: right;
}

.footer-link {
    margin-left: 3rem;
}

.our-story-title {
    font-size: 42px;
    line-height: 56px;
    font-weight: 500;
}

.our-story-left-gradient {
    background: linear-gradient(180deg, rgba(255, 107, 0, 0) 0%, rgba(255, 218, 88, 0.46) 100%);
    position: absolute;
    bottom: 0;
    width: 1000px;
    height: 800px;
    left: -80%;
    z-index: -9;
    filter: blur(50px);
}

.our-story-right-gradient {
    background: linear-gradient(97deg, rgba(255, 203, 253, 0.4) 3.7%, rgba(255, 0, 0, 0.4) 111%);
    position: absolute;
    bottom: -39%;
    width: 900px;
    height: 1000px;
    right: 0;
    z-index: -9;
    -webkit-filter: blur(50px);
    filter: blur(50px 50px 50px 50px);
    border-radius: 50% 0% 0% 50%;
}

.img-cloud-light {
    position: absolute;
    right: 17%;
    width: 217px;
    height: 217px;
    top: -12%;
    z-index: -1;
}

.img-cloud-light {
    position: absolute;
    right: 17%;
    width: 217px;
    height: 217px;
    top: -12%;
    z-index: -1;
}

.img-cloud-dark {
    position: absolute;
    left: 0%;
    width: 186px;
    height: 186px;
    top: 15%;
    z-index: -1;
}

.img-spring {
    position: absolute;
    left: 1%;
    width: 205px;
    height: 205px;
    top: 40%;
    z-index: -1;
}

.img-arc {
    position: absolute;
    right: 20%;
    width: 126px;
    height: 126px;
    top: 30%;
}

.img-green-toggle {
    position: absolute;
    right: 3%;
    width: 202px;
    height: 202px;
    top: 50%;
}

.cop-navbar-button {
    padding: 12px 24px;
}

.nav-signup {
    margin-right: 16px;
}

.cop-navbar-text {
    font-size: 14px;
    line-height: 16px;
}

.navbar-scrolled {
    background: #F3F7FC;
    z-index: 99;
    box-shadow: 0px 8px 26px 0px #a5b8d266;
}

.test-arrows {
    background: #A5B8D2;
    color: #FFFFFF;
}

.carousel-control-next-icon,
.carousel-control-prev-icon{
    width: 100%;
    height: 100%;
    background-image: none;
    background: none;
}

.carousel-control-next-icon:after{
    content: "\003e";
    font-size: 25px;
    color: white;
    background: #A5B8D2;
    border-radius: 54%;
    padding: 4px 17px;
    position: absolute;
    top: 50%;
    right: 0;
}

.carousel-control-prev-icon:after {
    content: "\003c";
    font-size: 25px;
    color: white;
    background: #A5B8D2;
    border-radius: 54%;
    padding: 4px 17px;
    position: absolute;
    top: 50%;
    left: 0;
}

.section-slider {
    margin-bottom: 200px;
}

.p-slider {
    background-color: #E0E5F2;
    border: 9px solid #ECF1F9;
    border-radius: 100px;
    width: 100%;
    margin-bottom: 120px;
    box-shadow: 4px 5px 17px 0px #a5b8d2 inset;
    position: relative;
    min-height: 154px;
    display: flex;
}

.p-slider-circle-blue, .p-slider-circle-green {
    height: 136px;
    width: 136px;
    border-radius: 50%;
    box-shadow: 2px 3px 17px 0px #00000078 inset, 0px -4px 7px 0px #61769380;
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-slider-circle-blue-inner, .p-slider-circle-green-inner {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: 0px 0px 6px 0px #61769380;
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-slider-circle-blue {
    background: linear-gradient(309deg, #0D45FF 26.14%, #100095 97.48%);
    position: absolute;
    left: 0;
    transition: left 0.5s linear;
}

.p-slider-circle-green {
    background: linear-gradient(292.37deg, #92FE9D 10.84%, #00C9FF 95.27%);
    box-shadow: none;
    right: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
}

.p-slider-circle-green-inner {
    box-shadow: inset 0px 1px 1px 0px rgb(0 0 0 / 11%);
    visibility: hidden;
    opacity: 0;
}

.slider-p-complete {
    width: 100%;
    position: absolute;
    top: 45%;
    left: 16%;
    visibility: none;
    opacity: 0;
}

.section-slider:hover .p-slider-circle-blue {
    left: 75%;
    visibility: none;
    opacity: 0;
    transition: left 0.7s linear, visibility 0s 0.7s ease-in-out, opacity 0s 0.7s ease-in-out;
}

.section-slider:hover .slider-p-complete {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s 0.7s linear, opacity 0s 0.7s linear;
}

.section-slider:hover .p-slider-circle-green {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s 0.7s linear, opacity 0s 0.7s linear;
}

.section-slider:hover .p-slider-circle-green-inner {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s 0.7s, opacity 0.5s 0.7s ease-in-out;
}

.complete_circle_gradient {
    background: linear-gradient(292.37deg, #92FE9D 10.84%, #00C9FF 95.27%);
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    height: 150px;
    filter: blur(100px);
    z-index: 9999;
    border-radius: 50%;
}

@-webkit-keyframes bounce-down {
    25% {
         -webkit-transform: translateY(-4px);
    }
    50%, 100% {
         -webkit-transform: translateY(0);
    }
    75% {
         -webkit-transform: translateY(4px);
    }
}

@keyframes bounce-down {
     25% {
          transform: translateY(-4px);
     }
     50%, 100% {
          transform: translateY(0);
     }
     75% {
          transform: translateY(4px);
     }
}

.google-btn {
    width: 100%;
    border: 2px solid #0D45FF;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #0D45FF;
}

.row .top-buttons .btn.top-button {
    margin-right: 16px;
}

.feature-describe {
    padding: 200px 80px;
}

.our-story-content {
    padding-right: 48px;
}

@media only screen and (max-width: 600px) {
    .execution-gradient, .our-story-left-gradient, .our-story-right-gradient, .testimonial-gradient, .section-1-blur-gradient, .calendar-gradient {
        display: none;
    }
    .task-blue-gradient {
        display: none;
    }
    .task-pink-gradient {
        display: none;
    }
    .section-title {
        font-size: 34px;
        line-height: 42px;
        font-weight: 500;
    }

    .section.top-section .top-section-content-l {
        margin-top: 10%;
        padding-left: 32px;
    }

    .top-button {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 16px;
    }

    .top-buttons {
        text-align: center;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image-border-left {
        right: 0;
        padding: 16px 0 16px 16px;
    }

    .toggle-icon {
        width: 89px;
        height: 89px;
        top: -48px;
        left: -48px;
    }   
    .cardboard-icon {
        width: 64px;
        height: 79px;
        top: -55px;
        left: 55px;
    }   
    .calendar-icon {
        width: 92px;
        height: 92px;
        top: -30px;
        left: 140px;
    }   
    .dialog-icon {
        width: 118px;
        height: 104px;
        top: -65px;
        right: 0px;
    }
    .image-1 {    
        margin-top: 25%;
        padding-left: 40px;
        margin-bottom: 300px;
    }

    .section.task-section, .execution-section {
        flex-direction: column-reverse;
        margin-top: 0%;
    }

    .task-list-r {
        margin-top: 0%;
    }

    .task-content-section, .calendar-content-section, .execution-content-section {
        padding: 40px;
        padding-bottom: 0;
    }

    .cal-sub-cont {
        margin-bottom: 45px !important;
    }

    .calendar-image {
        margin-top: 0;
        padding-left: 40px;
        margin-bottom: 400px;
    }

    .list-item {
        margin-bottom: 16px;
    }

    .feature-title, .section-price-card-title, .slider-title, .our-story-title {
        font-size: 24px;
        line-height: 32px;
    }

    .section-sub-content, .slider-subcontent  {
        font-size: 14px;
        line-height: 24px;
    }

    .index-list li {
        font-size: 17px;
        line-height: 30px;
    }

    .icon-width {
        width: 24px;
    }

    .image-2 {
        margin-top: 0;
        margin-bottom: 400px;
    }

    .section {
        min-height: unset;
    }


    .image-border-right {
        left: 0;
        margin-right: 32px;
        padding: 16px 16px 16px 0;
    }

    .calendar-section {
        margin-top: 0;
    }

    .calendar-img-wrapper {
        padding: 16px 0 16px 16px;
    }

    .execution-image-border {
        padding: 16px 16px 16px 16px;
    }

    .feature-describe {
        padding: 32px;
    }

    .feature-visibility {
        margin-bottom: 50px;
    }

    .feature-collaboration {
        margin-bottom: 50px;
    }

    .price-content {
        margin-top: 100px;
    }

    .price-subcontent {
        width: unset;
        padding: 0 20px;
        font-size: 16px;
        line-height: 28px;
    }

    .price-change {
        font-size: 18px;
        line-height: 30px;
    }

    .section-price-card {
        padding: 30px;
    }

    .price-card {
        margin: 0;
        padding: 38px 37px;
        margin-bottom: 16px;
    }

    .section-price-feature {
        padding: 40px 32px;
        padding-bottom: 0;
    }

    .section-price-feature-2 {
        padding-top: 0;
        margin-bottom: 200px;
    }

    .col-price-feature {
        margin-bottom: 24px;
    }

    .section-testimonial {
        padding: 0 30px;
        margin-right: -15px;
        margin-left: -15px;
    }

    .testimonial-card {
        height: unset;
        padding: 62px 30px;
    }

    .home-cont {
        padding-right: 15px;
        padding-left: 15px;
    }

    .p-slider {
        min-height: 100px;
        margin-bottom: 40px;
    }

    .p-slider-circle-blue, .p-slider-circle-green {
        height: 82px;
        width: 82px;
    }

    .p-slider-circle-blue-inner, .p-slider-circle-green-inner {
        width: 70px;
        height: 70px;
    }

    .p-slider-circle-green-inner  img {
        width: 50px;
    }

    .slider-title {
        margin-bottom: 8px;
    }

    .get-started-buttons {
        flex-direction: column;
    }

    .get-started-button {
        margin-bottom: 16px;
    }

    .section-slider {
        margin-bottom: 100px;
    }

    .our-story-content {
        padding: 0 32px;
        margin-bottom: 60px;
    }

    .img-cloud-light {
        width: 112px;
        height: 112px;
        top: -10%;
        right: 5%;
    }

    .img-cloud-dark {
        width: 96px;
        height: 96px;
        top: 20%;
        left: 10%;
    }

    .img-spring {
        width: 106px;
        height: 106px;
        top: 40%;
        left: 7%;
    }

    .img-arc {
        width: 65px;
        height: 65px;
        top: 33%;
        right: 8%;
    }

    .img-green-toggle {
        width: 104px;
        height: 104px;
        top: 50%;
        right: 0%;
    }

    .section-footer {
        margin-right: -15px;
        margin-left: -15px;
        padding: 80px 24px 37px 24px;
    }

    .social-icons {
        display: flex;
        justify-content: space-around;
    }
    
    .social-icon {
        margin-left: 0;
    }

    .footer-row {
        font-size: 13px;
        margin-top: 30px;
        flex-direction: column;
    }

    .footer-copyright {
        margin-bottom: 16px;
        font-size: 13px;
    }

    .footer-links {
        margin-top: 16px;
        text-align: left;
    }

    .footer-link {
        margin-right: 3rem;
        margin-left: 0;
    }
    
    .nav-hamburger {
        position: absolute;
        right: 0;
    }
    
    .collapse-modal {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
        bottom: 0;
        height: 100vh !important;
        z-index: 999;
        width: 100%;
        text-align: center;
    }

    .nav-signup {
        margin-right: 0;
        margin-bottom: 16px;
    }
    
    .navbar-collapse .navbar-collapse-header {
        border-bottom: none;
    }

    .navbar-collapse .navbar-toggler :nth-child(1) {
        transform: none;
    }

    .price-feature-title {
        font-size: 20px;
        line-height: 32px;
    }

    .col-price-feature {
        padding: 0;
    }
    .slider-p-complete {
        width: 100%;
        position: absolute;
        font-size: 14px;
        top: 45%;
        left: 6%;
    }
}

@media only screen and (min-width: 1800px) {
    .slider-p-complete {
        left: 30%;
    }
}

@media only screen and (min-width: 2400px) {
    .slider-p-complete {
        left: 35%;
    }
}