.feature-wrapper {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e2e2e2;
}
.feature-vote-check {
    margin-right: 10px;
    font-size: 18px;
    cursor: pointer;
}
.feature-name {
    font-size: 14px;
}
.feature-desc {
    font-size: 12px;
    color: #a2a2a2;
}
.voted {
    color: #69ceef;
}
.not-voted {
    color: #8e8e8e;
}
.feature-count {
    padding: 5px;
    background: #dadada;
    color: #69ceef;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}