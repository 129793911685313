.some-div {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #A3AED0; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
} 
 
.some-div span { 
    background:#fff; 
    padding:0 10px; 
}

.login-signup-link {
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
}

.login-forgot-link {
    font-weight: 700;
    cursor: pointer;
}

.login-forgot-link:hover {
    text-decoration: underline;
}

.outroute-login-col-gradient {
    background: linear-gradient(60deg, #FC00FF 0%, #00DBDE 100%);
    position: absolute;
    width: 600px;
    height: 650px;
    -webkit-filter: blur(90px);
    filter: blur(90px);
    border-radius: 40%;
    left: 0%;
}

.login-blur-image {
    background: linear-gradient(220deg, #aaa9eb 2.7%, #e29df8 93.27%);
    position: absolute;
    width: 400px;
    height: 130px;
    -webkit-filter: blur(60px);
    filter: blur(60px);
    border-radius: 40%;
    left: 10%;
    bottom: 0%;
    z-index: 2;
}

.right-img-toggle.login-toggle {
    top: 35%;
    left: 5%;
}

.right-img-cardboard.login-cardboard {
    top: 10%;
    left: 45%;
    width: 170px;
    height: 170px;
}

.right-img-calendar.login-calendar {
    top: 30%;
    left: 70%;
    width: 192px;
    height: 192px;
}

.right-img-calendar.login-dialog {
    top: 55%;
    left: 45%;
    width: 350px;
    height: 350px;
}

@media only screen and (min-width: 1800px) {
    .login-blur-image {
        left: 7%;
        bottom: 15%;
    }

    .login-right-img {
        margin-left: 5%;
    }
}

@media only screen and (min-width: 2400px) {
    .login-blur-image {
        left: 5%;
        bottom: 25%;
    }
}