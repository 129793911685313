.cardElementContainer {
    height: 40px;
    display: flex;
    align-items: center;
}

.cardElementContainer .StripeElement {
    width: 100%;
    padding: 15px;
}

.submitButton {
    display: block;
    height: 40px;
    width: 100%;
    font-size: inherit;
    background-color: #f6a4eb;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
    border-radius: 4px;
    opacity: 1;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.submitButton:disabled {
    background-color: #7795f8;
    opacity: 0.5;
    box-shadow: none;
}